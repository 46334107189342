.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* CAROUSEL  */
.carousel-image {
  width: 100%; /* Adjust width as needed */
  height: 300px; /* Adjust height as needed */
  object-fit: cover; /* This will maintain the aspect ratio */
}

.carousel-image-fadeout {
  position: relative;
  overflow: hidden;
}

.carousel-image-fadeout::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: 
    linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, 1) 100%),
    linear-gradient(to top, transparent, rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, 1) 100%);
}

.carousel-text {
  position: absolute;
  bottom: 0;
  left: 5%;
  right: 0;
  text-align: left;
  z-index: 10; /* Ensures text is above the gradient */
}

/* MENU CARDS */

.card-with-fading-border {
  position: relative;
  border: 4px solid transparent;
  border-radius: 16px;
  margin-top: 5em;
}

.card-with-fading-border::after {
  position: absolute;
  top: -4px; bottom: -4px;
  left: -4px; right: -4px;
  background: linear-gradient(to bottom, #FE8639, transparent);
  content: '';
  z-index: -1;
  border-radius: 16px;
}

/* Logo in carousel */

.static-logo {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 2; /* Ensure the logo is above other elements */
}

/* Add more styling as needed */
.static-logo img {
  width: 50px; /* Adjust the width as needed */
  height: auto;
}


body {
  background-color: black;
}

.carousel-item-container {
  height: 300px; /* Adjust the height as needed */
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel-item-container img,
.carousel-item-container .react-player {
  max-height: 100%;
  object-fit: cover; /* or 'contain' based on your preference */
}
